import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import UserMenu from './UserMenu';
import PageMenu from './PageMenu';
import { MD_LAYOUT_BREAKPOINT } from '../../themes/GlobalThemeProvider';
import SearchContainer from './SearchContainer';
import { colorPalette } from 'ri-components';
import { useTranslation } from 'react-i18next';

const { black, grey } = colorPalette;

const useStyles = makeStyles((theme) => ({
  rightMenuButtonGroup: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    height: '72px',
    boxShadow: `0 12px 16px -8px ${grey[3]}`,
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(2, 2.5),
    width: '100%',
    maxWidth: '1200px',
    [theme.breakpoints.between(0, MD_LAYOUT_BREAKPOINT)]: {
      justifyContent: 'space-between',
      padding: theme.spacing(1.75, 2.5, 2.25),
    },
  },
  flexGrow: {
    flexGrow: '1',
  },
  modal: {
    width: '200vw',
    height: '200vh',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: black,
    opacity: 0.5,
    zIndex: 5,
  },
}));

const Navbar = ({ claimListLabel, adminLabel }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navbarRef = useRef(null);

  const [isDisplayModal, setIsDisplayModal] = useState(false);

  return (
    <div className={classes.flexGrow}>
      {isDisplayModal && <div className={classes.modal} data-testid='modal-background' />}
      <AppBar position='static' color='inherit' ref={navbarRef} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <PageMenu navbarRef={navbarRef} claimListLabel={t(claimListLabel)} adminLabel={t(adminLabel)} />

          <div className={classes.flexGrow} />

          <SearchContainer isDisplayModal={isDisplayModal} setIsDisplayModal={setIsDisplayModal} />

          <div className={classes.flexGrow} />

          <div className={classes.rightMenuButtonGroup}>
            <UserMenu navbarRef={navbarRef} />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;

Navbar.defaultProps = {
  claimListLabel: 'Claim List',
  adminLabel: 'Admin',
};

Navbar.propTypes = {
  claimListLabel: PropTypes.string,
  adminLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
};
